import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCuVA8PRaaraDOn8oWckD03OM0XhRWt_6o',
  authDomain: 'enwam-8794a.firebaseapp.com',
  projectId: 'enwam-8794a',
  storageBucket: 'enwam-8794a.appspot.com',
  messagingSenderId: '158664121222',
  appId: '1:158664121222:web:2112bae15a53141bb36a07',
  measurementId: 'G-NHDHTEJ0MP'
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}
