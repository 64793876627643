<template>
  <Navbar title="Registro de envios" :class="{'show' : showNavbar}">
    <template v-slot:close>
      <div @click="$emit('close')" class="close">
        Cerrar
      </div>
    </template>
    <template v-slot:content>
      <!--Highlight-->
      <div class="bg-secondary p-4 rounded mb-8 text-white">
        <span class="text-xs uppercase mb-2 block">Monto a pagar</span>
        <span class="text-2xl font-semibold">{{
            Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'PEN'
            }).format(document.total)
          }}</span>
        </div>
      <!--End-->
      <!--Terms-->
      <div class="text-sm flex gap-4 items-center mb-4">
        <input v-model="terms" type="checkbox" name="terms" id="terms">
        <label for="terms">Acepto que la tarifa de mis envíos puede variar.</label>
      </div>
      <!--End-->
      <!--WhatsApp-->
      <a
        :href="`https://api.whatsapp.com/send/?phone=51930910495&text=¡Hola!+Tengo+una+consulta+o+solicitud.+&type=phone_number&app_absent=0`"
        target="_blank"
        class="bg-[#25d366] text-white rounded flex items-center justify-center text-sm gap-4 px-4 py-2 mb-8">
        <whats-app-icon></whats-app-icon>
        <span>¿Deseas saber la tarifa exacta? Escríbenos</span>
      </a>
      <!---End--->
      <div v-if="terms">
        <!--Payment methods-->
        <div class="border border-gray-500 bg-white border-dashed p-4 text-sm space-y-4 mb-8 whitespace-pre-line">
          <span v-html="convertStringToHTML(data.content.accounts)"></span>
        </div>
        <!--End-->
        <!--Observation-->
        <div class="mb-8">
          <div class="form-group">
          <label for="name">Observación</label>
            <input v-model="document.observation" class="input" type="text" id="name">
          <span v-if="this.validationErrors.observation" class="error">{{ this.validationErrors.observation }}</span>
        </div>
        </div>
        <!--End-->
        <!--Amount-->
        <div class="bg-secondary p-3 rounded text-white">
          <span class="text-xs uppercase mb-2 block">Monto detectado</span>
          <span class="text-xl font-semibold">{{
              Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'PEN'
              }).format(detectedAmount)
            }}</span>
        </div>
        <!--End-->
        <!--Alert-->
        <div v-if="validationWasDone && document.client.paymentMethod.value === 'Contado'"
             class="text-sm bg-primary text-white p-2 rounded mt-4">
          <span v-if="validationWasDone && detectedAmount > document.total ">Los montos no coinciden, podrás enviar tu orden pero será previamente evaluada.</span>
          <span v-else-if="validationWasDone && detectedAmount < document.total">Los montos no coinciden, por favor revisa el comprobante.</span>
        </div>
        <!--End-->
        <!--Voucher-->
        <div class="form-group mt-8">
          <input @change="updateImage" class="file" ref="uploadVoucher" type="file" accept="image/*">
          <button :disabled="validateAmountIsLoading" :class="{'is-loading': validateAmountIsLoading}"
                  class="btn btn--outline btn--w100" @click="$refs.uploadVoucher.click()">
            <span v-if="!validateAmountIsLoading">Adjuntar comprobante</span>
          </button>
          <img class="preview" v-if="attach" :src="preview" alt="Voucher">
        </div>
        <!--End-->
      </div>
    </template>
    <template v-slot:footer>
      <button
        :disabled="progress || !terms || validateAmountIsLoading || !validationWasDone || detectedAmount < document.total"
              :class="{'is-loading': progress}" @click="save"
              class="btn">
        <span v-if="!progress">Enviar</span>
      </button>
    </template>
  </Navbar>
  <Shadow :class="{'show' : showNavbar}"/>
</template>

<script>
import Navbar from '../Navbar.vue'
import Shadow from '../Shadow.vue'
import moment from 'moment'
import axios from 'axios'
import * as yup from 'yup'
import general from '../../../mixins/general'

import { db, FieldValue, storage } from '@/firebase/firebaseConfig'
import WhatsAppIcon from '../atoms/icons/WhatsAppIcon.vue'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero',
    truncate: 'Formato incorrecto'
  }
})

export default {
  name: 'Pay',
  components: {
    WhatsAppIcon,
    Navbar,
    Shadow
  },
  mixins: [general],
  props: {
    showNavbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      document: {},
      detectedAmount: 0,
      validateAmountIsLoading: false,
      validationWasDone: false,
      progress: false,
      terms: false,
      validationErrors: {},
      attach: null,
      url: null,
      preview: '',
      moment: moment
    }
  },
  watch: {
    showNavbar (value) {
      if (value) {
        this.document = {
          ...this.data
        }
        this.document.total = this.list.length === 1 ? this.document.total + 5 : this.document.total
        this.terms = false
        this.validationErrors = {}
        this.preview = ''
        this.attach = null
        this.validationWasDone = this.document.client.paymentMethod.value === 'Crédito'
        this.detectedAmount = this.document.total
      }
    }
  },
  methods: {
    /**
     * Save data in firestore
     * */
    async save () {
      try {
        if (this.url || this.document.client.paymentMethod.value === 'Crédito') {
          this.progress = true
          this.validationErrors = {}
          const obj = {
            ...this.document,
            voucher: this.url,
            observation: this.document.observation || '',
            createdAt: FieldValue.serverTimestamp(),
            amountsMatch: this.document.total === this.detectedAmount
          }

          // Save in order in firestore
          const orderDoc = await db.collection('orders').add({
            ...obj,
            quantityShipments: this.list.length
          })
          // End

          // Save detail
          const batch = db.batch()
          this.list.forEach((detail) => {
            const detailDocRef = db.collection('detail').doc()
            batch.set(detailDocRef, {
              orderId: orderDoc.id,
              clientId: obj.client.id,
              client: obj.client,
              ...detail,
              pickUpDate: new Date(detail.pickUpDate + ' 05:00:00'),
              deadline: new Date(detail.deadline + ' 05:00:00'),
              createdAt: FieldValue.serverTimestamp(),
              amountsMatch: this.document.total === this.detectedAmount
            })
          })
          await batch.commit()
          // End

          this.$emit('done')
        } else {
          this.$toast.error('Debes adjuntar el comprobante de pago')
        }
      } catch (err) {
        this.$toast.error('Error al registrar pago')
      } finally {
        this.progress = false
      }
    },
    updateImage (input) {
      try {
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = async e => {
            this.validateAmountIsLoading = true
            this.preview = e.target.result
            this.attach = input.target.files[0]
            this.url = await this.submitFile(this.attach)

            const response = await axios.post('https://us-central1-enwam-8794a.cloudfunctions.net/vision-validateVoucher', {
              imageUrl: this.url
            }, {})

            const amountRegex = /S\/\s?(\d+(\.\d{1,2})?)/i
            const match = response.data.text.match(amountRegex)
            const extractedAmount = parseFloat(match[1])
            this.detectedAmount = extractedAmount
            this.validateAmountIsLoading = false
            this.validationWasDone = true
          }
          reader.readAsDataURL(input.target.files[0])
        }
      } catch (e) {
        this.$toast.error('Error al cargar imagen')
        this.validateAmountIsLoading = false
      }
    },
    /**
     * Save doc in firebase storage
     * @param doc
     * @returns {Promise<unknown>}
     */
    submitFile (doc) {
      return new Promise((resolve, reject) => {
        this.validateAmountIsLoading = true
        const docName = Math.random().toString(36).substr(1, 20)
        const fileExtension = '.' + doc.name.split('.').pop()
        const docNameFinal = docName + fileExtension

        const storageRef = storage.ref(`vouchers/${docNameFinal}`)
        storageRef.put(doc)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then(async (url) => {
              resolve(url)
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
</script>
