<template>
  <div class="menu"
       :class="{'show': showMenuMobile,'w-[80px]': collapse}">
    <!--Menu-->
    <ul class="space-y-4">
      <li class="w-full">
        <router-link
          to="/"
          class=" w-full flex gap-2 items-center py-2 px-4 rounded transition-all duration-500"
          :class="{'bg-secondary text-white': ($route.name ==='home') , '!px-0 justify-center' : collapse}">
          <home-icon class="w-[20px]"></home-icon>
          <span v-if="!collapse">Home</span>
        </router-link>
      </li>
      <li class="w-full">
        <router-link
          to="/shipments"
          class=" w-full flex gap-2 items-center py-2 px-4 rounded  transition-all duration-500"
          :class="{'bg-secondary text-white': ($route.name ==='shipments') , '!px-0 justify-center' : collapse}">
          <rocket-icon class="w-[20px]"></rocket-icon>
          <span v-if="!collapse">Envíos</span>
        </router-link>
      </li>
    </ul>
    <!--End-->
    <!--Close-->
    <div @click="showMenuMobile =false" class="menu__close">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Close</title>
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="32"
          d="M368 368L144 144M368 144L144 368"
        />
      </svg>
    </div>
    <!--Toggle icon-->
    <span @click="collapse = !collapse" class="absolute bottom-[140px] left-[calc(50%-12px)] cursor-pointer">
      <toggle-icon></toggle-icon>
    </span>
    <!--End-->
    <!--Log out-->
    <div v-if="!collapse"
         class="menu__logout desktop">
      <span @click="logout">Cerrar sesión</span>
    </div>
    <div @click="logout" class="menu__logout mobile">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Log Out</title>
        <path
          d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
      </svg>
    </div>
    <!--End-->
    <!--Logo-->
    <img class="logo" src="/logo.png" alt="Logo enwam">
    <!--End-->
  </div>
  <!--Hamburger-->
  <div class="hamburger" @click="showMenuMobile= !showMenuMobile">
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Menu</title>
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="32"
        d="M80 160h352M80 256h352M80 352h352"
      />
    </svg>
  </div>
  <!--End-->
</template>

<script>
import { auth } from '@/firebase/firebaseConfig'
import HomeIcon from './atoms/icons/HomeIcon.vue'
import RocketIcon from './atoms/icons/RocketIcon.vue'
import ToggleIcon from './atoms/icons/ToggleIcon.vue'

export default {
  name: 'Menu',
  components: {
    ToggleIcon,
    RocketIcon,
    HomeIcon
  },
  data () {
    return {
      showMenuMobile: false,
      collapse: false
    }
  },
  watch: {
    '$route' (value) {
      this.showMenuMobile = false
    },
    collapse (value) {
      this.$emit('toggleMenu')
    }
  },
  methods: {
    async logout () {
      try {
        await auth.signOut()
        this.$store.dispatch('showLogin')
        await this.$router.push('/login')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
