const general = {
  methods: {
    convertStringToHTML (text) {
      while (text.includes('*')) {
        const start = text.indexOf('*')
        const end = text.indexOf('*', start + 1)
        const textBefore = text.slice(0, start)
        const textAfter = text.slice(end + 1)
        const textInside = text.slice(start + 1, end)

        text = textBefore + '<strong class=\'font-support-sass font-semibold\'>' + textInside + '</strong>' + textAfter

        if (text.includes('!!')) {
          text = text.replace('!!', '\n')
        }
      }
      return text
    }
  }
}

export default general
