import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '@/firebase/firebaseConfig'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login')
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: import('../views/shipments/Index.vue'),
    meta: {
      auth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(() => {
    // get firebase current user
    const firebaseCurrentUser = auth.currentUser
    // If auth required, check login. If login fails redirect to login page
    if (to.meta.auth) {
      if (!(firebaseCurrentUser)) {
        store.dispatch('showLogin')
        return next('/login')
      } else {
        store.dispatch('hideLogin')
        return next()
      }
    } else {
      return next()
    }
  })
})

export default router
