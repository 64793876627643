import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

import './index.css'

// Firebase
import '@/firebase/firebaseConfig'

import '@vueform/toggle/themes/default.css'

createApp(App)
  .use(store)
  .use(VueToast)
  .use(VCalendar, {})
  .use(router)
  .mount('#app')
