import { createStore } from 'vuex'
import { auth, db } from '@/firebase/firebaseConfig'

export default createStore({
  state: {
    isProgress: false,
    login: true,
    user: {}
  },
  mutations: {
    SHOW_PROGRESS (state) {
      state.isProgress = true
    },
    HIDE_PROGRESS (state) {
      state.isProgress = false
    },
    SHOW_LOGIN (state) {
      state.login = true
    },
    HIDE_LOGIN (state) {
      state.login = false
    },
    SET_USER (state, user) {
      state.user = user
    }
  },
  actions: {
    showProgress ({ commit }) {
      commit('SHOW_PROGRESS')
    },
    hideProgress ({ commit }) {
      commit('HIDE_PROGRESS')
    },
    showLogin ({ commit }) {
      commit('SHOW_LOGIN')
    },
    hideLogin ({ commit }) {
      commit('HIDE_LOGIN')
    },
    async setUser ({ commit }) {
      // Get user
      const queryUser = await db.collection('users').where('uid', '==', auth.currentUser.uid).get()
      const user = {
        id: queryUser.docs[0].id,
        ...queryUser.docs[0].data()
      }
      // End
      commit('SET_USER', user)
    }
  },
  modules: {}
})
