<template>
  <Menu v-if="!login" @toggleMenu="collapseMenu = !collapseMenu"/>
  <Progress/>
  <div class="content p-10" :class="{'content--login':login, 'ml-[120px] w-[calc(100%-120px)]' : collapseMenu}">
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in" appear>
        <component :is="Component">
          <template #header>
            <app-header></app-header>
          </template>
        </component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import Progress from '@/components/Progress'

export default {
  name: 'App',
  components: {
    Menu,
    Progress
  },
  data () {
    return {
      collapseMenu: false
    }
  },
  computed: {
    login () {
      return this.$store.state.login
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/global";

.route-enter-from {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.2s ease-out;
}

.route-leave-to {
  opacity: 0;
}

.route-leave-active {
  transition: all 0.2s ease-in;
}

</style>
